import classNames from 'classnames'
import { graphql, StaticQuery } from 'gatsby'
import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import LocalizedLink from 'src/components/LocalizedLink'
import GradientButton from 'src/components/GradientButton'

import styles from './styles.module.css'

export default class extends Component {
  state = {
    isFaqClosed: true,
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query($locale: String) {
            footer: cockpitFooter(lang: { eq: $locale }) {
              contactUs {
                value {
                  title {
                    value
                  }
                  emailAddress {
                    value
                  }
                }
              }
              followUs {
                value {
                  title {
                    value
                  }
                  social {
                    value {
                      link {
                        value
                      }
                      icon {
                        value {
                          icon {
                            value {
                              publicURL
                            }
                          }
                        }
                      }
                      cockpitId
                    }
                  }
                }
              }
              headline {
                value
              }
              address1 {
                value {
                  city {
                    value
                  }
                  street {
                    value
                  }
                  place {
                    value
                  }
                  zipCode {
                    value
                  }
                  telephone {
                    value
                  }
                }
              }
              address2 {
                value {
                  city {
                    value
                  }
                  street {
                    value
                  }
                  place {
                    value
                  }
                  zipCode {
                    value
                  }
                  telephone {
                    value
                  }
                }
              }
              address3 {
                value {
                  city {
                    value
                  }
                  street {
                    value
                  }
                  place {
                    value
                  }
                  zipCode {
                    value
                  }
                  telephone {
                    value
                  }
                }
              }
              faq {
                value {
                  value {
                    question {
                      value
                    }
                    answer {
                      value
                    }
                  }
                }
              }
              privacy {
                value {
                  value {
                    name {
                      value
                    }
                    to {
                      value {
                        to {
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
            navbar: cockpitNavbar(lang: { eq: $locale }) {
              cockpitId
              logo {
                value {
                  publicURL
                }
              }
              callToActionName {
                value
              }
              callToActionLink {
                value {
                  to {
                    value
                  }
                }
              }
              sections {
                value {
                  value {
                    name {
                      value
                    }
                    link {
                      value {
                        to {
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={({ footer, navbar }) => {
          return (
            <>
              <div className={styles.footerHeaderContainer}>
                <span
                  role="button"
                  className={styles.footerHeader}
                  onClick={this.toggleFaq}
                  onKeyDown={this.onKeyDown}
                  tabIndex={0}
                >
                  <div className={styles.headline}>{footer.headline.value}</div>
                  <div
                    className={classNames(styles.arrow, {
                      [styles.reversed]: !this.state.isFaqClosed,
                    })}
                  />
                </span>
              </div>
              <div
                className={classNames(styles.faq, {
                  [styles.hidden]: this.state.isFaqClosed,
                })}
              >
                {footer.faq.value.map((faqElement, index) => (
                  <div
                    key={faqElement.value.question.value}
                    className={styles.faqElement}
                  >
                    <div className={styles.questionContainer}>
                      <div className={styles.number}>
                        {`${index + 1}`.padStart(2, '0')}
                      </div>
                      <div className={styles.question}>
                        {faqElement.value.question.value}
                      </div>
                    </div>
                    <div className={styles.answerContainer}>
                      <div className={styles.number}> {''} </div>
                      <div className={styles.answer}>
                        {faqElement.value.answer.value}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className={styles.footerBody}>
                <LocalizedLink to="/">
                  <ReactSVG
                    src={navbar.logo.value.publicURL}
                    className={styles.logoContainer}
                    beforeInjection={svg => {
                      svg.classList.add(styles.logo)
                    }}
                  />
                </LocalizedLink>
                <div
                  className={classNames(styles.contactUs, styles.footerSection)}
                >
                  <div className={styles.contactUsSection}>
                    <div>{footer.contactUs.value.title.value}</div>
                      <LocalizedLink to="/contact">
                        <p className={styles.emailAddress}>
                          {footer.contactUs.value.emailAddress.value}
                        </p>
                      </LocalizedLink>
                  </div>
                  <div className={styles.address}>
                    {this.createAddress(
                      footer.address1.value.place.value,
                      footer.address1.value.street.value,
                      footer.address1.value.city.value,
                      footer.address1.value.zipCode.value,
                      footer.address1.value.telephone.value
                    )}
                  </div>
                  <div
                    className={classNames(styles.address, styles.showOnMedium)}
                  >
                    {this.createAddress(
                      footer.address2.value.place.value,
                      footer.address2.value.street.value,
                      footer.address2.value.city.value,
                      footer.address2.value.zipCode.value,
                      footer.address2.value.telephone.value
                    )}
                  </div>
                </div>
                <div
                  className={classNames(styles.followUs, styles.footerSection)}
                >
                  <div>
                    <div>{footer.followUs.value.title.value}</div>
                    <div className={styles.iconContainer}>
                      {footer.followUs.value.social.value.map(icon => (
                        <a
                          key={icon.cockpitId}
                          href={icon.link.value}
                          rel="external nofollow"
                        >
                          <ReactSVG
                            key={icon.cockpitId}
                            src={icon.icon.value.icon.value.publicURL}
                            className={styles.icon}
                            beforeInjection={svg => {
                              svg.classList.add(styles.logo)
                            }}
                          />
                        </a>
                      ))}
                    </div>
                  </div>
                  <div
                    className={classNames(styles.address, styles.hideOnMedium)}
                  >
                    {this.createAddress(
                      footer.address2.value.place.value,
                      footer.address2.value.street.value,
                      footer.address2.value.city.value,
                      footer.address2.value.zipCode.value,
                      footer.address2.value.telephone.value
                    )}
                  </div>
                  <div
                    className={classNames(
                      styles.linkContainer,
                      styles.showOnMedium
                    )}
                  >
                    {navbar.sections.value.map(section => (
                      <LocalizedLink
                        className={styles.link}
                        to={section.value.link.value.to.value}
                        key={section.value.name.value}
                      >
                        {section.value.name.value}
                      </LocalizedLink>
                    ))}
                  </div>
                  <div
                    className={classNames(styles.address, styles.showOnMedium)}
                  >
                    {this.createAddress(
                      footer.address3.value.place.value,
                      footer.address3.value.street.value,
                      footer.address3.value.city.value,
                      footer.address3.value.zipCode.value,
                      footer.address3.value.telephone.value
                    )}
                  </div>
                </div>
                <div
                  className={classNames(
                    styles.links,
                    styles.footerSection,
                    styles.hideOnMedium
                  )}
                >
                  <div className={styles.linkContainer}>
                    {navbar.sections.value.map(section => (
                      <LocalizedLink
                        className={styles.link}
                        to={section.value.link.value.to.value}
                        key={section.value.name.value}
                      >
                        {section.value.name.value}
                      </LocalizedLink>
                    ))}
                    {footer.privacy.value.map(section => (
                      <LocalizedLink
                        className={styles.link}
                        to={section.value.to.value.to.value}
                        key={section.value.name.value}
                      >
                        {section.value.name.value}
                      </LocalizedLink>
                    ))}
                  </div>
                  <div className={styles.address}>
                    {this.createAddress(
                      footer.address3.value.place.value,
                      footer.address3.value.street.value,
                      footer.address3.value.city.value,
                      footer.address3.value.zipCode.value,
                      footer.address3.value.telephone.value
                    )}
                  </div>
                </div>
                <div
                  className={classNames(
                    styles.buttonContainer,
                    styles.footerSection
                  )}
                >
                  <GradientButton
                    link={navbar.callToActionLink.value.to.value}
                    text={navbar.callToActionName.value}
                    buttonStyle={classNames(
                      styles.buttonSize,
                      styles.buttonTextSize
                    )}
                  />
                </div>
              </div>
            </>
          )
        }}
      />
    )
  }

  toggleFaq = () => {
    this.setState({ isFaqClosed: !this.state.isFaqClosed })
  }

  toggleFaqOnEnter = event => {
    if (event.key === 'Enter') {
      this.toggleFaq()
    }
  }

  createAddress = (place, street, city, zipCode, telephone) => (
    <span className={styles.addressSection}>
      <div className={styles.addressTitle}>{place}</div>
      <div className={styles.addressBody}>
        <div>{street}</div>
        <div>{city}</div>
        <div>{zipCode}</div>
      </div>

      <a href={'tel:' + telephone} className={styles.addressTelephone}>
        {telephone}
      </a>
    </span>
  )
}
