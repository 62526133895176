import classNames from 'classnames'
import { graphql, StaticQuery } from 'gatsby'
import React, { Component } from 'react'
import { Elastic } from 'react-burgers'
import { ReactSVG } from 'react-svg'
import LocalizedLink from 'src/components/LocalizedLink'
import GradientButton from 'src/components/GradientButton'

import styles from './styles.module.css'

class Navbar extends Component {
  state = {
    fullscreen: false,
    visible: true,
  }

  shouldComponentUpdate(_, nextState) {
    if (this.state.scrolled === nextState.scrolled) {
      if (this.state.scrollY !== nextState.scrollY) {
        return false
      }
    }
    return true
  }

  scroll = () => {
    if (window.scrollY < 1 && this.state.scrolled) {
      this.setState({
        scrolled: false,
      })
    }

    if (window.scrollY < this.state.scrollY) {
      this.setState({
        visible: true,
        scrolled: window.scrollY >= 1,
      })
    } else if (window.scrollY >= 1) {
      this.setState({
        visible: false,
      })
    }

    this.setState({
      scrollY: window.scrollY,
    })
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scroll)
  }

  componentWillUnmount() {
    this.enableScrolling()
    window.removeEventListener('scroll', this.scroll)
  }

  removeFullscreen = () => {
    this.enableScrolling()
    this.setState({ fullscreen: false })
  }

  createPageLinks = (sections, buttonLink, buttonText) => (
    <>
      {sections.map(section => (
        <LocalizedLink
          getProps={this.getLinkStyle}
          to={section.value.link.value.to.value}
          key={section.value.name.value}
        >
          {section.value.name.value}
        </LocalizedLink>
      ))}
      <GradientButton
        link={buttonLink}
        text={buttonText}
        buttonStyle={styles.buttonSize}
      />
    </>
  )

  disableScrolling = () => {
    const x = window.scrollX
    const y = window.scrollY
    window.onscroll = function() {
      window.scrollTo(x, y)
    }
  }

  enableScrolling = () => {
    window.onscroll = function() {}
  }

  getLinkStyle = ({ isPartiallyCurrent }) => ({
    className: classNames(styles.link, {
      [styles.currentPageLink]: isPartiallyCurrent,
    }),
  })

  render() {
    return (
      <StaticQuery
        query={graphql`
          query($locale: String) {
            cockpitNavbar(lang: { eq: $locale }) {
              cockpitId
              logo {
                value {
                  publicURL
                }
              }
              callToActionName {
                value
              }
              callToActionLink {
                value {
                  to {
                    value
                  }
                }
              }
              sections {
                value {
                  value {
                    name {
                      value
                    }
                    link {
                      value {
                        to {
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={({ cockpitNavbar }) => {
          const logoUrl = cockpitNavbar.logo.value.publicURL
          const sections = cockpitNavbar.sections.value
          const buttonLink = cockpitNavbar.callToActionLink.value.to.value
          const buttonText = cockpitNavbar.callToActionName.value

          return (
            <div>
              <div
                className={classNames(styles.navbar, {
                  [styles.hidden]:
                    !this.state.visible && !this.state.fullscreen,
                })}
              >
                <LocalizedLink to="/">
                  <ReactSVG
                    src={logoUrl}
                    className={styles.logoContainer}
                    beforeInjection={svg => {
                      svg.classList.add(styles.logo)
                    }}
                  />
                </LocalizedLink>
                <div className={styles.burger}>
                  <Elastic
                    lineHeight={4}
                    lineSpacing={6}
                    padding={0}
                    width={30}
                    color={styles.backgroundColor}
                    active={this.state.fullscreen}
                    onClick={() => {
                      if (this.state.fullscreen) {
                        this.enableScrolling()
                      } else {
                        this.disableScrolling()
                      }
                      this.setState({ fullscreen: !this.state.fullscreen })
                    }}
                  />
                </div>
                <div className={classNames(styles.links, styles.hideOnMobile)}>
                  {this.createPageLinks(
                    sections,
                    buttonLink,
                    buttonText
                  )}
                </div>
              </div>
              <div
                className={classNames(styles.fullscreenClosed, {
                  [styles.fullscreenOpened]: this.state.fullscreen,
                })}
              >
                <div className={styles.fullscreenPageLinkContainer}>
                  {this.createPageLinks(
                    sections,
                    buttonLink,
                    buttonText
                  )}
                </div>
              </div>
            </div>
          )
        }}
      />
    )
  }
}

export default Navbar
