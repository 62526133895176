import React from 'react'

import styles from './styles.module.css'

const Header = ({ title, description }) => {
  return (
    <div className={styles.titleContainer}>
      <div className={styles.title}>{title}</div>
      <div className={styles.descriptionContainer}>
        <div className={styles.blueLine}></div>
        <div className={styles.description}>{description}</div>
      </div>
      <div className={styles.arrowContainer}>
        <div className={styles.arrow}></div>
      </div>
    </div>
  )
}
export default Header
