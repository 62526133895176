import { graphql, StaticQuery } from 'gatsby'
import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { IntlProvider } from 'react-intl'
import '@formatjs/intl-pluralrules/dist/locale-data/en'
import '@formatjs/intl-pluralrules/dist/locale-data/fr'
import FirebaseContext from 'src/contexts/Firebase'
import Footer from 'src/components/Footer'
import Navbar from 'src/components/Navbar'
import en from 'src/messages/en.json'
import fr from 'src/messages/fr.json'

import styles from './styles.module.css'

const messages = { en, fr }

const Layout = ({ children, pageContext }) => {
  const handleFirstTab = event => {
    if (event.keyCode === 9) {
      document.body.classList.add('user-is-tabbing')
      window.removeEventListener('keydown', handleFirstTab)
      window.addEventListener('mousedown', handleMouseDownOnce)
    }
  }

  const handleMouseDownOnce = () => {
    document.body.classList.remove('user-is-tabbing')

    window.removeEventListener('mousedown', handleMouseDownOnce)
    window.addEventListener('keydown', handleFirstTab)
  }

  const [firebase, setFirebase] = useState(null)

  useEffect(() => {
    window.addEventListener('keydown', handleFirstTab)
    return () => {
      window.removeEventListener('keydown', handleFirstTab)
      window.addEventListener('mousedown', handleMouseDownOnce)
    }
  })

  useEffect(() => {
    import('src/services/Firebase').then(module => setFirebase(module.default))
  }, [setFirebase])

  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              title
              baseUrl
              url {
                fr
                en
              }
              description {
                fr
                en
              }
              keywords {
                fr
                en
              }
              defaultLocale
            }
          }
          file(relativePath: { eq: "images/thumbnail.png" }) {
            publicURL
          }
        }
      `}
      render={({
        site: {
          siteMetadata: {
            title,
            baseUrl,
            url,
            description,
            keywords,
            defaultLocale,
          },
        },
        file,
      }) => {
        const { locale } = pageContext
        const currentLocale = locale || defaultLocale

        const meta = [
          {
            property: 'og:title',
            content: title,
          },
          {
            property: 'og:image',
            content: baseUrl + file.publicURL,
          },
          {
            property: 'og:image:url',
            content: baseUrl + file.publicURL,
          },
          {
            property: 'og:image:type',
            content: 'image/png',
          },
          {
            property: 'og:type',
            content: 'website',
          },
          {
            property: 'og:image:width',
            content: 1080,
          },
          {
            property: 'og:image:height',
            content: 1080,
          },
          {
            property: 'og:url',
            content: url[currentLocale],
          },
          {
            property: 'og:description',
            content: description[currentLocale],
          },
          {
            name: 'description',
            content: description[currentLocale],
          },
          {
            name: 'keywords',
            content: keywords[currentLocale],
          },
        ]

        return (
          <IntlProvider
            locale={currentLocale}
            messages={messages[currentLocale]}
          >
            <FirebaseContext.Provider value={firebase}>
              <div>
              <Helmet
  title={title}
  meta={meta}
>
  <html lang={currentLocale} />
</Helmet>
                <div>
                  <Navbar className={styles.navbar} />
                  <div className={styles.content}>{children}</div>
                  <Footer />
                </div>
              </div>
            </FirebaseContext.Provider>
          </IntlProvider>
        )
      }}
    />
  )
}

export default Layout