import classNames from 'classnames'
import React from 'react'
import LocalizedLink from 'src/components/LocalizedLink'

import styles from './styles.module.css'

export default ({ link, text, buttonStyle }) => {
  return (
    <div className={classNames(styles.pill, buttonStyle)}>
      <div className={styles.gradient} />
      <div className={styles.textContainer}>
        <LocalizedLink to={`${link}`} className={styles.buttonText}>
          {text}
        </LocalizedLink>
      </div>
    </div>
  )
}
